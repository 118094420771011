import * as React from 'react';
import { SVGProps } from 'react';

const SvgStarDollarIcon = (props: SVGProps) => {
  return (
    <svg
      width={28}
      height={29}
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m24.83 10.155-6.942-1.009-3.103-6.292a.879.879 0 0 0-1.57 0l-3.103 6.292-6.943 1.01a.873.873 0 0 0-.484 1.492l5.023 4.897-1.187 6.916a.873.873 0 0 0 1.27.921L14 21.117l6.21 3.265a.874.874 0 0 0 1.269-.921l-1.187-6.916 5.023-4.897a.873.873 0 0 0 .254-.5.872.872 0 0 0-.738-.993Z"
        fill="#FB923C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.624 14.498a1.72 1.72 0 0 0-.491-.561 3.254 3.254 0 0 0-.746-.407 12.193 12.193 0 0 0-.945-.329 12.091 12.091 0 0 1-.466-.15 1.978 1.978 0 0 1-.336-.147.663.663 0 0 1-.203-.17.356.356 0 0 1-.067-.217c0-.083.02-.154.062-.212a.459.459 0 0 1 .17-.142.89.89 0 0 1 .246-.078c.093-.016.193-.024.3-.024.102 0 .204.014.304.042a1.828 1.828 0 0 1 .538.253c.08.054.15.106.216.156l.043.037c.045.035.086.065.123.089a.222.222 0 0 0 .124.036c.054 0 .109-.026.164-.076l.739-.73a.714.714 0 0 0 .094-.111.195.195 0 0 0 .032-.108c0-.038-.022-.085-.065-.139a1.322 1.322 0 0 0-.177-.172 2.705 2.705 0 0 0-.258-.185 5.692 5.692 0 0 0-.312-.184 3.23 3.23 0 0 0-.795-.292c-.082-.019-.166-.03-.249-.042v-.605a.166.166 0 0 0-.08-.143.277.277 0 0 0-.152-.054h-.908c-.071 0-.124.02-.16.059a.196.196 0 0 0-.053.138v.628c-.1.018-.205.032-.3.059a2.547 2.547 0 0 0-.81.373c-.222.161-.395.355-.515.582a1.578 1.578 0 0 0-.182.755c0 .26.049.497.145.711.097.215.241.411.435.589s.435.339.724.483c.29.145.63.28 1.015.405.087.026.19.06.31.1.119.04.233.09.343.146.11.056.202.12.278.194a.338.338 0 0 1 .113.248.69.69 0 0 1-.04.238.484.484 0 0 1-.139.197.708.708 0 0 1-.263.136 1.41 1.41 0 0 1-.413.051c-.177 0-.347-.028-.51-.085a2.396 2.396 0 0 1-.485-.237 2.858 2.858 0 0 1-.293-.208 8.533 8.533 0 0 0-.21-.167.9.9 0 0 0-.116-.08.192.192 0 0 0-.092-.028c-.042 0-.096.023-.165.067l-.835.738c-.074.063-.111.12-.111.17 0 .048.037.107.111.18l.02.017.019.018.014.013c.155.158.333.306.534.443.201.138.416.257.643.36.227.103.464.184.71.242.021.005.044.007.065.011v.657c0 .132.071.197.213.197h.908c.155 0 .232-.065.232-.197v-.643c.145-.025.289-.051.425-.092.342-.103.639-.249.892-.437s.451-.415.597-.682c.144-.266.217-.564.217-.891 0-.311-.059-.575-.176-.793Z"
        fill="#fff"
      />
    </svg>
  );
};
export default SvgStarDollarIcon;
