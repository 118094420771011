import * as React from 'react';
import { SVGProps } from 'react';

const SvgMagicWandIcon = (props: SVGProps) => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#a)"
        stroke="#475569"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M16.875 10v3.75M15 11.875h3.75M6.25 3.125v3.75M4.375 5h3.75M13.125 14.375v2.5M11.875 15.625h2.5M11.25 6.25l2.5 2.5M14.192 3.307 3.308 14.191a.625.625 0 0 0 0 .884l1.616 1.616c.244.244.64.244.884 0L16.692 5.807a.625.625 0 0 0 0-.884l-1.616-1.616a.625.625 0 0 0-.884 0Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgMagicWandIcon;
