import * as React from 'react';
import { SVGProps } from 'react';

const SvgBonusNavIcon = (props: SVGProps) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.25 6.75h-3.289A2.768 2.768 0 0 0 18 4.647 3.057 3.057 0 0 0 14.854 1.5a2.769 2.769 0 0 0-2.01.93A5.15 5.15 0 0 0 12 3.786a5.15 5.15 0 0 0-.844-1.358 2.768 2.768 0 0 0-2.01-.929A3.058 3.058 0 0 0 6 4.648a2.768 2.768 0 0 0 .93 2.009c.035.03.072.06.109.093H3.75a1.5 1.5 0 0 0-1.5 1.5v3a1.5 1.5 0 0 0 1.5 1.5v6a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-6a1.5 1.5 0 0 0 1.5-1.5v-3a1.5 1.5 0 0 0-1.5-1.5Zm-6.281-3.327a1.283 1.283 0 0 1 .937-.422h.046a1.559 1.559 0 0 1 1.548 1.6 1.283 1.283 0 0 1-.422.938c-.89.788-2.366 1.065-3.281 1.163.112-.993.422-2.436 1.172-3.279Zm-6.009.034A1.56 1.56 0 0 1 9.055 3h.046a1.284 1.284 0 0 1 .938.422c.786.889 1.064 2.363 1.161 3.274-.91-.094-2.385-.375-3.273-1.162a1.284 1.284 0 0 1-.422-.937 1.556 1.556 0 0 1 .452-1.14h.003ZM3.75 8.25h7.5v3h-7.5v-3Zm1.5 4.5h6v6h-6v-6Zm13.5 6h-6v-6h6v6Zm1.5-7.5h-7.5v-3h7.5v3Z"
        fill="#000"
      />
    </svg>
  );
};
export default SvgBonusNavIcon;
