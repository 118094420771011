import * as React from 'react';
import { SVGProps } from 'react';

const SvgNotVerifyFactor1X = (props: SVGProps) => {
  return (
    <svg
      width={24}
      height={25}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          d="M12 24.91c6.627 0 12-5.373 12-12 0-6.628-5.373-12-12-12s-12 5.372-12 12c0 6.627 5.373 12 12 12Z"
          fill="#F59E0B"
          opacity={0.8}
        />
        <path
          d="m8.616 12.518 1.248-1.92h1.848l-2.16 3.036c.48.6.916 1.184 1.308 1.752.392.568.704 1.076.936 1.524h-1.92a55.29 55.29 0 0 0-.24-.444 8.38 8.38 0 0 0-.324-.564c-.12-.2-.248-.396-.384-.588a6.31 6.31 0 0 0-.384-.54c-.12.152-.244.32-.372.504a13.3 13.3 0 0 0-.348.564l-.336.552c-.104.184-.196.356-.276.516H5.388c.096-.2.228-.436.396-.708a21.82 21.82 0 0 1 1.164-1.716c.216-.296.428-.572.636-.828-.36-.504-.72-1.012-1.08-1.524-.36-.52-.716-1.032-1.068-1.536h1.932l1.248 1.92Zm4.136-2.196c.232-.096.472-.204.72-.324.256-.128.504-.264.744-.408.24-.152.468-.308.684-.468.224-.168.424-.344.6-.528h1.248v8.316H14.96v-5.988c-.24.16-.508.308-.804.444-.296.128-.584.24-.864.336l-.54-1.38Z"
          fill="#2D3344"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v25H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgNotVerifyFactor1X;
