import * as React from 'react';
import { SVGProps } from 'react';

const SvgJivoMobile = (props: SVGProps) => {
  return (
    <svg
      width={49}
      height={49}
      viewBox="0 0 49 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          d="M24.5 49C38.031 49 49 38.031 49 24.5S38.031 0 24.5 0 0 10.969 0 24.5 10.969 49 24.5 49Z"
          fill="#7DA2FF"
        />
        <path
          d="M24.537 10.236c-9.264 0-16.773 5.904-16.773 13.183 0 3.446 1.684 6.589 4.442 8.937-.105 2.29-.58 5.367-2.257 6.954 3.204 0 6.484-2.11 8.43-3.63 1.907.595 3.984.921 6.158.921 9.263 0 16.773-5.903 16.773-13.182 0-7.28-7.51-13.183-16.773-13.183Z"
          fill="#0F172A"
        />
        <path
          d="m33 16.098-8.904 8.926-4.245-3.477L16 24.465v5.158l3.751-2.843 4.624 3.787L33 21.921v-5.823ZM33.08 16.035l-.012-.005.003-.004.01.01Z"
          fill="#7DA2FF"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h49v49H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgJivoMobile;
