import * as React from 'react';
import { SVGProps } from 'react';

const SvgContactsIcon = (props: SVGProps) => {
  return (
    <svg
      width={24}
      height={25}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.8 16.85a.75.75 0 0 0 1.05-.15 4.874 4.874 0 0 1 7.8 0 .75.75 0 1 0 1.2-.9 6.363 6.363 0 0 0-2.569-2.033 3.75 3.75 0 1 0-5.057 0A6.362 6.362 0 0 0 7.65 15.8a.75.75 0 0 0 .15 1.05ZM10.5 11a2.25 2.25 0 1 1 4.5 0 2.25 2.25 0 0 1-4.5 0Zm9-8.25H6a1.5 1.5 0 0 0-1.5 1.5V6.5H3A.75.75 0 0 0 3 8h1.5v3.75H3a.75.75 0 1 0 0 1.5h1.5V17H3a.75.75 0 1 0 0 1.5h1.5v2.25a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5V4.25a1.5 1.5 0 0 0-1.5-1.5Zm0 18H6V4.25h13.5v16.5Z"
        fill="#475569"
      />
    </svg>
  );
};
export default SvgContactsIcon;
