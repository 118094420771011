import * as React from 'react';
import { SVGProps } from 'react';

const SvgVerificationPendingIcon = (props: SVGProps) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)" fill="#F97316">
        <path d="M12 22C6.475 22 2 17.523 2 12S6.475 2 12 2c5.522 0 10 4.477 10 10s-4.478 10-10 10Zm4.5-3.5V17L12 12l4.5-5.5V5.171h-9V6.5L12 12v-1l-2-2.5 2 .5 2-.5-2 2.5v1l.5 5 3.5.5v.5H8v-.5l3.5-.5.5-5-4.5 5v1.5h9Z" />
        <path d="M12 22C6.475 22 2 17.523 2 12S6.475 2 12 2c5.522 0 10 4.477 10 10s-4.478 10-10 10Zm4.5-3.5V17L12 12l4.5-5.5V5.171h-9V6.5L12 12v-1l-2-2.5 2 .5 2-.5-2 2.5v1l.5 5 3.5.5v.5H8v-.5l3.5-.5.5-5-4.5 5v1.5h9Z" />
        <path d="M11.999 22c-5.523 0-10-4.477-10-10s4.477-10 10-10 10 4.477 10 10-4.477 10-10 10Zm4.501-3.5V17l-4.501-5L16.5 6.5V5.171h-9V6.5l4.499 5.5v-1L9.5 8l2.499.5L14.5 8l-2.501 3v1l.501 4.5 3.5 1v.5H8v-.5l3.5-1 .499-4.5L7.5 17v1.5h9Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgVerificationPendingIcon;
