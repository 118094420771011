import * as React from 'react';
import { SVGProps } from 'react';

const SvgKeyReturnIcon = (props: SVGProps) => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#a)"
        stroke="#475569"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M13.75 8.125v2.5h-7.5" />
        <path d="M8.125 8.75 6.25 10.625 8.125 12.5" />
        <path d="M16.875 3.75H3.125a.625.625 0 0 0-.625.625v11.25c0 .345.28.625.625.625h13.75c.345 0 .625-.28.625-.625V4.375a.625.625 0 0 0-.625-.625Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgKeyReturnIcon;
