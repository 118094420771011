import * as React from 'react';
import { SVGProps } from 'react';

const SvgEmptyTableIllustration = (props: SVGProps) => {
  return (
    <svg
      width={80}
      height={64}
      viewBox="0 0 80 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M30 0h41.667L75.5 4 80 8.5V64H30V0Z" fill="#9CA3AF" />
      <path d="M30 0h41.667v8.5H80V64H30V0Z" fill="#E5E7EB" />
      <path
        fill="#F9FAFB"
        d="M36 20h38v4H36zM36 28h38v4H36zM36 36h38v4H36zM36 44h38v4H36z"
      />
      <path
        d="m24.062 36.597 2.913 3.055L13.688 54.01a2.814 2.814 0 0 1-4.102.03l-.72-.755a2.814 2.814 0 0 1 .225-4.096l14.97-12.592Z"
        fill="#D1D5DB"
      />
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={8}
        y={36}
        width={19}
        height={19}
      >
        <path
          d="m24.062 36.597 2.913 3.055L13.688 54.01a2.814 2.814 0 0 1-4.102.03l-.72-.755a2.814 2.814 0 0 1 .225-4.096l14.97-12.592Z"
          fill="#D1D5DB"
        />
      </mask>
      <g mask="url(#a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.39 45.016a20.54 20.54 0 0 1-2.647-2.338 20.538 20.538 0 0 1-2.209-2.754l4.296-3.613c.544.892 1.19 1.74 1.941 2.526a14.9 14.9 0 0 0 2.431 2.059l-3.812 4.12Z"
          fill="#9CA3AF"
        />
      </g>
      <rect
        x={35.641}
        y={10}
        width={26.736}
        height={26.736}
        rx={13.368}
        transform="rotate(46.358 35.64 10)"
        fill="#FFB800"
        style={{
          mixBlendMode: 'color',
        }}
      />
      <rect
        x={35.641}
        y={10}
        width={26.736}
        height={26.736}
        rx={13.368}
        transform="rotate(46.358 35.64 10)"
        stroke="#D1D5DB"
        strokeWidth={4.221}
      />
    </svg>
  );
};
export default SvgEmptyTableIllustration;
