import * as React from 'react';
import { SVGProps } from 'react';

const SvgHistoryWithdrawIcon = (props: SVGProps) => {
  return (
    <svg
      width={29}
      height={27}
      viewBox="0 0 29 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 3.739a1.2 1.2 0 0 0-1.41-1.181L2.99 4.986A1.2 1.2 0 0 0 2 6.167V20a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1h-3V3.739ZM16 7V4.694L4 6.837V7h12ZM4 9v10h16V9H4Zm11 4h3v2h-3v-2Z"
        fill="#000"
      />
      <path
        d="M28.5 20a6.5 6.5 0 1 0-13 0 6.5 6.5 0 0 0 13 0Z"
        fill="#F9FAFB"
      />
      <path
        d="m22.5 19.991 3.5-.06-3.93 4.068L18 20.07l3.5-.06-.07-4 1-.017.07 4Z"
        fill="#F87171"
      />
    </svg>
  );
};
export default SvgHistoryWithdrawIcon;
