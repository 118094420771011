import * as React from 'react';
import { SVGProps } from 'react';

const SvgNotVerifyFactor2X = (props: SVGProps) => {
  return (
    <svg
      width={24}
      height={25}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          d="M12 24.91c6.627 0 12-5.373 12-12 0-6.628-5.373-12-12-12s-12 5.372-12 12c0 6.627 5.373 12 12 12Z"
          fill="#94A3B8"
          opacity={0.8}
        />
        <path
          d="m8.616 12.518 1.248-1.92h1.848l-2.16 3.036c.48.6.916 1.184 1.308 1.752.392.568.704 1.076.936 1.524h-1.92a55.29 55.29 0 0 0-.24-.444 8.38 8.38 0 0 0-.324-.564c-.12-.2-.248-.396-.384-.588a6.31 6.31 0 0 0-.384-.54c-.12.152-.244.32-.372.504a13.3 13.3 0 0 0-.348.564l-.336.552c-.104.184-.196.356-.276.516H5.388c.096-.2.228-.436.396-.708a21.82 21.82 0 0 1 1.164-1.716c.216-.296.428-.572.636-.828-.36-.504-.72-1.012-1.08-1.524-.36-.52-.716-1.032-1.068-1.536h1.932l1.248 1.92Zm9.248-1.74c0 .304-.06.596-.18.876a4.2 4.2 0 0 1-.468.816 6.955 6.955 0 0 1-.648.744 27.6 27.6 0 0 1-.708.684c-.12.112-.252.24-.396.384a12.152 12.152 0 0 0-.744.828.976.976 0 0 0-.18.3h3.576v1.5h-5.544a1.934 1.934 0 0 1-.024-.336v-.288c0-.384.06-.736.18-1.056a4 4 0 0 1 .492-.888c.2-.28.424-.54.672-.78.256-.24.508-.48.756-.72l.54-.516c.168-.168.316-.328.444-.48.128-.16.228-.316.3-.468.072-.16.108-.32.108-.48 0-.352-.1-.6-.3-.744-.2-.144-.448-.216-.744-.216-.216 0-.42.036-.612.108a2.765 2.765 0 0 0-.516.24c-.152.088-.284.18-.396.276-.112.088-.196.16-.252.216l-.888-1.248c.352-.328.76-.596 1.224-.804a3.59 3.59 0 0 1 1.512-.324c.488 0 .908.056 1.26.168.352.112.64.272.864.48.232.2.4.448.504.744a2.7 2.7 0 0 1 .168.984Z"
          fill="#2D3344"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v25H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgNotVerifyFactor2X;
