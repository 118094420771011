import * as React from 'react';
import { SVGProps } from 'react';

const SvgGraph = (props: SVGProps) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.75 19.5a.75.75 0 0 1-.75.75H3a.75.75 0 0 1-.75-.75v-15a.75.75 0 0 1 1.5 0v8.847l4.756-4.16a.75.75 0 0 1 .944-.035l5.513 4.135 5.543-4.85a.75.75 0 1 1 .988 1.126l-6 5.25a.75.75 0 0 1-.944.035l-5.513-4.133L3.75 15.34v3.41H21a.75.75 0 0 1 .75.75Z"
        fill="#9CA3AF"
      />
    </svg>
  );
};
export default SvgGraph;
