import * as React from 'react';
import { SVGProps } from 'react';

const SvgPhoneProfileIllustration = (props: SVGProps) => {
  return (
    <svg
      width={400}
      height={220}
      viewBox="0 0 400 220"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity={0.4} filter="url(#a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M57 27c-7.18 0-13 5.82-13 13v101c0 7.18 5.82 13 13 13h14V62c0-7.18 5.82-13 13-13h230v-9c0-7.18-5.82-13-13-13H57Z"
          fill="#F97316"
        />
      </g>
      <path
        opacity={0.6}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M314 49v92c0 7.18-5.82 13-13 13H71V62c0-7.18 5.82-13 13-13h230Z"
        fill="#F97316"
      />
      <rect
        opacity={0.6}
        x={71}
        y={49}
        width={285}
        height={144}
        rx={13}
        fill="#F97316"
      />
      <path
        opacity={0.53}
        d="M71 62c0-7.18 5.82-13 13-13h259c7.18 0 13 5.82 13 13v8H71v-8Z"
        fill="#F8FAFC"
      />
      <rect x={92} y={80} width={36} height={36} rx={18} fill="#fff" />
      <rect x={143} y={86} width={141} height={9} rx={4.5} fill="#fff" />
      <rect
        opacity={0.53}
        x={143}
        y={98}
        width={71}
        height={9}
        rx={4.5}
        fill="#F8FAFC"
      />
      <rect
        opacity={0.53}
        x={220}
        y={98}
        width={119}
        height={9}
        rx={4.5}
        fill="#F8FAFC"
      />
      <path
        d="M92 133a5 5 0 0 1 5-5h68a5 5 0 0 1 5 5v60H92v-60ZM175 133a5 5 0 0 1 5-5h67a5 5 0 0 1 5 5v60h-77v-60ZM257 133a5 5 0 0 1 5-5h68a5 5 0 0 1 5 5v60h-78v-60Z"
        fill="#fff"
      />
      <circle cx={88} cy={60} r={4} fill="#F97316" />
      <circle cx={103} cy={60} r={4} fill="#F8FAFC" />
      <circle cx={118} cy={60} r={4} fill="#F59E0B" />
      <defs>
        <filter
          id="a"
          x={26}
          y={16}
          width={306}
          height={163}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={7} />
          <feGaussianBlur stdDeviation={9} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.537014 0 0 0 0 0.703924 0 0 0 0 0.870833 0 0 0 1 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_59_7336"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_59_7336"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
export default SvgPhoneProfileIllustration;
