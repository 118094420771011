import * as React from 'react';
import { SVGProps } from 'react';

const SvgProfileIcon = (props: SVGProps) => {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        className="svg-fill"
        d="M16 3a13 13 0 1 0 13 13A13.013 13.013 0 0 0 16 3ZM9.26 24.688a8 8 0 0 1 13.48 0 10.979 10.979 0 0 1-13.48 0ZM12 15a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm12.22 8.301a9.957 9.957 0 0 0-4.508-3.593 6 6 0 1 0-7.425 0A9.957 9.957 0 0 0 7.78 23.3a11 11 0 1 1 16.44 0Z"
        fill="#fff"
      />
    </svg>
  );
};
export default SvgProfileIcon;
