import * as React from 'react';
import { SVGProps } from 'react';

const SvgGoldStatus = (props: SVGProps) => {
  return (
    <svg
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)" fillRule="evenodd" clipRule="evenodd">
        <path
          d="M20.744 20.775c0 4.947-2.494 9.294-6.255 11.773-.6.396-1.103.341-1.607 0-3.734-2.534-6.255-6.84-6.255-11.773 0-4.946 2.575-9.157 6.255-11.773.558-.395.94-.463 1.607 0 3.72 2.562 6.255 6.827 6.255 11.773Zm26.789 0c0 4.947 2.494 9.294 6.255 11.773.6.396 1.117.341 1.621 0 3.734-2.534 6.255-6.84 6.255-11.773 0-4.946-2.576-9.157-6.255-11.773-.558-.395-.94-.463-1.621 0-3.72 2.562-6.255 6.827-6.255 11.773Z"
          fill="#78C2A4"
        />
        <path
          d="M52.003 30.954c-2.466 4.279-2.494 9.293-.477 13.327.313.64.79.858 1.39.804 4.497-.327 8.83-2.794 11.31-7.072 2.466-4.279 2.344-9.225.477-13.327-.286-.627-.586-.872-1.39-.804-4.497.368-8.844 2.793-11.31 7.072Zm-35.728 0c2.466 4.279 2.493 9.293.477 13.327-.328.64-.79.858-1.39.804-4.51-.327-8.844-2.794-11.31-7.072-2.467-4.279-2.344-9.225-.464-13.327.287-.627.586-.872 1.39-.804 4.497.368 8.83 2.793 11.297 7.072Z"
          fill="#95D6A4"
        />
        <path
          d="M50.177 36.432a13.835 13.835 0 0 0 1.362 7.85c.314.64.79.858 1.39.803 4.497-.327 8.83-2.793 11.31-7.072 1.594-2.752 2.112-5.764 1.772-8.666a13.81 13.81 0 0 1-1.772 5.355c-2.466 4.279-6.8 6.745-11.31 7.072-.613.04-1.076-.164-1.39-.804a13.56 13.56 0 0 1-1.362-4.538Zm-32.063 0a13.835 13.835 0 0 1-1.363 7.85c-.327.64-.79.858-1.39.803-4.51-.327-8.843-2.793-11.31-7.072-1.58-2.752-2.098-5.764-1.771-8.666a13.81 13.81 0 0 0 1.771 5.355c2.467 4.279 6.8 6.745 11.31 7.072.6.04 1.077-.164 1.39-.804a13.88 13.88 0 0 0 1.363-4.538Z"
          fill="#78C2A4"
        />
        <path
          d="M53.856 39.484c-4.279 2.467-6.813 6.8-7.072 11.31-.041.709.259 1.132.804 1.404 4.06 1.962 9.048 2.003 13.326-.477 4.279-2.466 6.65-6.813 7.073-11.31.068-.695-.069-1.05-.804-1.403-4.075-1.935-9.048-2.004-13.327.476Zm-39.435 0c4.279 2.467 6.8 6.8 7.072 11.31.041.709-.259 1.132-.804 1.404-4.06 1.949-9.048 1.99-13.326-.49C3.083 49.24.726 44.893.29 40.397c-.068-.695.069-1.05.804-1.404 4.075-1.921 9.048-1.99 13.327.49Z"
          fill="#B3E59F"
        />
        <path
          d="M47.111 48.533a15.398 15.398 0 0 0-.327 2.248c-.041.709.259 1.13.804 1.403 4.06 1.963 9.048 2.004 13.326-.476 4.28-2.467 6.65-6.814 7.073-11.31.054-.518-.014-.845-.368-1.131-.886 3.679-3.12 7.058-6.705 9.13-4.278 2.466-9.265 2.438-13.326.476a1.583 1.583 0 0 1-.477-.34Zm-25.931 0c.163.735.272 1.485.327 2.248.04.709-.259 1.13-.804 1.403-4.074 1.963-9.062 2.004-13.34-.476C3.084 49.24.727 44.894.29 40.398c-.055-.518.013-.845.368-1.131.885 3.679 3.12 7.058 6.704 9.13 4.278 2.466 9.266 2.438 13.326.463.191-.082.355-.191.49-.327Z"
          fill="#95D6A4"
        />
        <path
          d="M50.735 47.933c-4.946 0-9.293 2.493-11.773 6.254-.395.6-.34 1.104 0 1.608 2.535 3.734 6.84 6.255 11.773 6.255 4.947 0 9.157-2.576 11.774-6.255.395-.559.463-.94 0-1.608-2.562-3.706-6.84-6.254-11.774-6.254Zm-33.194 0c4.947 0 9.294 2.493 11.774 6.254.395.6.34 1.104 0 1.608-2.535 3.734-6.827 6.255-11.774 6.255-4.946 0-9.157-2.576-11.773-6.255-.395-.559-.463-.94 0-1.608 2.562-3.706 6.84-6.254 11.773-6.254Z"
          fill="#CCF49F"
        />
        <path
          d="M39.562 53.33a9.77 9.77 0 0 0-.613.858c-.396.6-.341 1.103 0 1.607 2.534 3.734 6.84 6.255 11.773 6.255 4.946 0 9.157-2.575 11.773-6.255.395-.558.463-.94 0-1.607a10.13 10.13 0 0 0-.64-.859c-2.644 3.23-6.582 5.423-11.147 5.423S42.151 56.6 39.562 53.33Zm-10.847 0c.218.272.423.558.613.858.396.6.341 1.103 0 1.607-2.534 3.734-6.826 6.255-11.773 6.255-4.946 0-9.157-2.575-11.773-6.255-.395-.558-.463-.94 0-1.607.204-.3.422-.586.64-.859 2.644 3.23 6.582 5.423 11.133 5.423 4.565 0 8.571-2.153 11.16-5.423Z"
          fill="#B3E59F"
        />
        <path
          d="M12.977 29.823a35.37 35.37 0 0 0 .585 3.312h41.18l-2.549-3.312H12.977Z"
          fill="#5490F9"
        />
        <path
          d="M34.139 33.12h20.59c.245-1.13.435-2.234.585-3.31H34.14v3.31Z"
          fill="#DA4A54"
        />
        <path
          d="M13.549 33.121c1.867 8.68 6.936 19.186 19.73 26.585.287.136.573.218.86.218V33.121h-20.59Z"
          fill="#DD636E"
        />
        <path
          d="M33.267 56.408c-11.283-6.227-16.843-14.635-19.5-22.306 2.03 8.489 7.195 18.49 19.513 25.604.286.136.572.218.859.218v-3.312c-.3 0-.586-.068-.873-.204Z"
          fill="#DA4A54"
        />
        <path
          d="M54.401 34.552C51.69 42.1 46.13 50.304 35.065 56.395c-.313.15-.627.218-.926.218v3.311c.286 0 .572-.068.858-.218 12.1-6.99 17.292-16.774 19.404-25.154Z"
          fill="#5490F9"
        />
        <path
          d="M33.543.381c-2.86 2.773-12.329 11.16-24.815 13.448-1.081.208-1.36.398-1.517 1.525-.872 6.48-2.965 35.663 26.106 52.368.715.364 1.447.364 2.197 0 16.375-9.41 22.862-22.77 25.251-33.809L34.433 0c-.297 0-.593.121-.89.381Z"
          fill="#F2CD2C"
        />
        <path
          d="M7.333 29.702c.174 1.352.419 2.756.75 4.211h52.7l-3.262-4.21H7.333Z"
          fill="#E6BD19"
        />
        <path
          d="M34.433 33.913h26.35c1.848-8.56 1.238-15.735.854-18.577-.157-1.109-.453-1.317-1.5-1.507C47.67 11.559 38.183 3.17 35.323.38c-.297-.259-.593-.38-.89-.38v33.913Z"
          fill="#C6A51A"
        />
        <path
          d="M50.783 17.844c0-.598-.495-1.082-1.105-1.082s-1.106.485-1.106 1.082c0 .232.075.446.202.622l-1.501 1.47a1.114 1.114 0 0 0-.477-.186v-3.786a1.09 1.09 0 0 0 .947-1.07c0-.597-.495-1.082-1.106-1.082-.61 0-1.106.485-1.106 1.083 0 .524.382.961.888 1.06v3.804a1.088 1.088 0 0 0-.888 1.061c0 .232.075.446.202.622l-1.513 1.481a1.11 1.11 0 0 0-.477-.194V18.91c.527-.082.93-.528.93-1.067 0-.598-.494-1.082-1.105-1.082-.61 0-1.106.485-1.106 1.082 0 .53.39.97.905 1.063v3.826a1.088 1.088 0 0 0-.905 1.063c0 .597.495 1.082 1.106 1.082.61 0 1.106-.485 1.106-1.082 0-.226-.071-.435-.192-.608L46 21.703c.124.086.266.148.42.178v3.803a1.088 1.088 0 0 0-.888 1.061c0 .598.495 1.082 1.106 1.082.61 0 1.105-.484 1.105-1.082 0-.544-.41-.994-.946-1.07V21.89c.535-.075.947-.524.947-1.069a1.06 1.06 0 0 0-.204-.624l1.501-1.469c.132.091.284.154.45.182v3.822c-.521.087-.918.53-.918 1.065 0 .597.495 1.082 1.106 1.082.61 0 1.105-.485 1.105-1.082 0-.535-.396-.978-.917-1.065V18.91c.52-.087.917-.53.917-1.065Zm2.077 1.721a1.15 1.15 0 0 0-1.162 1.139c0 .629.52 1.138 1.161 1.138a1.15 1.15 0 0 0 1.162-1.138 1.15 1.15 0 0 0-1.162-1.139Zm-12.386 0a1.15 1.15 0 0 0-1.161 1.139c0 .629.52 1.138 1.16 1.138a1.15 1.15 0 0 0 1.162-1.138 1.15 1.15 0 0 0-1.161-1.139Z"
          fill="#7A5E0F"
        />
        <path
          d="M34.433 33.914h26.35c.314-1.438.558-2.842.75-4.21h-27.1v4.21Z"
          fill="#A08615"
        />
        <path
          d="M8.083 33.913c2.389 11.039 8.876 24.4 25.25 33.81.367.173.733.277 1.1.277V33.913H8.082Z"
          fill="#C6A51A"
        />
        <path
          d="M27.94 41.219c0-.598-.495-1.082-1.106-1.082-.61 0-1.106.484-1.106 1.082 0 .232.075.446.202.622l-1.501 1.47a1.114 1.114 0 0 0-.477-.186V39.34a1.09 1.09 0 0 0 .947-1.07c0-.597-.495-1.081-1.106-1.081-.61 0-1.105.484-1.105 1.081 0 .525.381.962.888 1.061v3.804a1.088 1.088 0 0 0-.889 1.061c0 .232.076.446.203.622l-1.514 1.481a1.11 1.11 0 0 0-.476-.194v-3.818c.527-.082.93-.528.93-1.067 0-.598-.495-1.082-1.106-1.082-.61 0-1.105.484-1.105 1.082 0 .53.39.97.904 1.063v3.826a1.088 1.088 0 0 0-.904 1.063c0 .597.495 1.082 1.105 1.082s1.106-.485 1.106-1.082c0-.226-.07-.435-.191-.608l1.517-1.485c.123.086.265.148.42.178v3.803a1.088 1.088 0 0 0-.889 1.061c0 .598.495 1.082 1.106 1.082.61 0 1.106-.484 1.106-1.082 0-.544-.412-.993-.947-1.07v-3.786c.535-.075.947-.525.947-1.069a1.06 1.06 0 0 0-.204-.624l1.501-1.469c.132.091.284.155.45.182v3.822c-.521.087-.918.53-.918 1.065 0 .597.495 1.082 1.106 1.082.61 0 1.105-.485 1.105-1.082 0-.535-.396-.978-.917-1.065v-3.822c.52-.087.918-.53.918-1.065Zm2.076 1.721a1.15 1.15 0 0 0-1.161 1.139c0 .629.52 1.138 1.16 1.138a1.15 1.15 0 0 0 1.162-1.138 1.15 1.15 0 0 0-1.161-1.139Zm-12.386 0a1.15 1.15 0 0 0-1.161 1.139c0 .629.52 1.138 1.16 1.138a1.15 1.15 0 0 0 1.162-1.138 1.15 1.15 0 0 0-1.161-1.139Z"
          fill="#7A5E0F"
        />
        <path
          d="M33.316 63.512C18.877 55.61 11.762 44.917 8.362 35.162c2.598 10.795 9.207 23.515 24.972 32.56.366.174.732.278 1.099.278v-4.211c-.384 0-.75-.087-1.117-.277Z"
          fill="#A08615"
        />
        <path
          d="M60.364 35.732c-3.47 9.6-10.585 20.033-24.745 27.78a2.75 2.75 0 0 1-1.186.276V68c.366 0 .733-.086 1.099-.277 15.485-8.89 22.13-21.332 24.832-31.99Z"
          fill="#E6BD19"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h68v68H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgGoldStatus;
