import * as React from 'react';
import { SVGProps } from 'react';

const SvgDownArrowIcon = (props: SVGProps) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m12 13.172 4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222l4.95 4.95Z"
        fill="#1F2937"
      />
    </svg>
  );
};
export default SvgDownArrowIcon;
