import * as React from 'react';
import { SVGProps } from 'react';

const SvgLivenessIcon = (props: SVGProps) => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 1.875A8.125 8.125 0 1 0 18.125 10 8.133 8.133 0 0 0 10 1.875Zm0 15A6.875 6.875 0 1 1 16.875 10 6.883 6.883 0 0 1 10 16.875ZM6.25 8.437a.938.938 0 1 1 1.875 0 .938.938 0 0 1-1.875 0Zm7.5 0a.938.938 0 1 1-1.875 0 .938.938 0 0 1 1.875 0Zm-.084 3.75c-.803 1.39-2.14 2.188-3.666 2.188-1.526 0-2.862-.797-3.666-2.188a.625.625 0 1 1 1.082-.624c.583 1.008 1.5 1.562 2.584 1.562s2-.555 2.584-1.563a.625.625 0 1 1 1.082.626Z"
        fill="#64748B"
      />
    </svg>
  );
};
export default SvgLivenessIcon;
